import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { auth } from "./firebase";
import { onAuthStateChanged, signOut } from "firebase/auth";
import Auth from "./Auth";
import UserChallenges from "./UserChallenges";
import PrivateRoute from "./PrivateRoute";
import DailyChallengeDetails from "./DailyChallengeDetails";
import ChallengeProgress from "./ChallengeProgress";
import Feedback from "./Feedback";
import './App.css';  // Importar estilos globales

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  const handleLogout = () => {
    signOut(auth);
  };

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <Router>
      <div className={`App ${user ? '' : 'login-page'}`}>
        <div className="app-content">
          <header className="App-header">
            <div className="header-left">
              <img src="/100blows_logo.png" alt="100 Blows | Tu desafío diario de Alto Rendimiento" className="App-logo" />
            </div>
            {user ? (
              <div className="header-right">
                <p>Hola, {user.email}</p>
                <button className="logout-button" onClick={handleLogout}>Salir</button>
              </div>
            ) : null}
          </header>

          <Routes>
            <Route path="/login" element={<Auth />} />
            <Route
              path="/user-challenges"
              element={
                <PrivateRoute user={user}>
                  <UserChallenges userId={user?.uid} />
                </PrivateRoute>
              }
            />
            <Route
              path="/daily-challenge/:challengeId/:dayNumber"
              element={
                <PrivateRoute user={user}>
                  <DailyChallengeDetails userId={user?.uid} />
                </PrivateRoute>
              }
            />
            <Route
              path="/challenge-progress/:challengeId"
              element={
                <PrivateRoute user={user}>
                  <ChallengeProgress userId={user?.uid} />
                </PrivateRoute>
              }
            />
            <Route
              path="/feedback/:challengeId"
              element={
                <PrivateRoute user={user}>
                  <Feedback userId={user?.uid} />
                </PrivateRoute>
              }
            />
            <Route
              path="/"
              element={user ? <Navigate to="/user-challenges" /> : <Navigate to="/login" />}
            />
            {/* Ruta para capturar cualquier URL no existente */}
            <Route
              path="*"
              element={user ? <Navigate to="/user-challenges" /> : <Navigate to="/login" />}
            />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
