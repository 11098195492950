import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";
import { db } from "./firebase";
import './ChallengeProgress.css'; // Asegúrate de tener los estilos en este archivo

const ChallengeProgress = ({ userId }) => {
  const { challengeId } = useParams();
  const [tasks, setTasks] = useState([]);
  const [challenge, setChallenge] = useState(null);
  const [completedCount, setCompletedCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);
  const [remainingCount, setRemainingCount] = useState(0);
  const [progressPercentage, setProgressPercentage] = useState(0);
  const navigate = useNavigate(); // Hook para navegar entre rutas

  useEffect(() => {
    const fetchChallengeProgress = async () => {
      try {
        const challengeDoc = await getDoc(doc(db, "users", userId, "user_challenges", challengeId));
        if (challengeDoc.exists()) {
          setChallenge(challengeDoc.data());

          const tasksSnapshot = await getDocs(collection(db, "challenges", challengeDoc.data().challenge_id, "tasks"));
          const taskList = tasksSnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
          }));

          taskList.sort((a, b) => a.day_number - b.day_number);
          setTasks(taskList);

          // Calcular estadísticas
          let completed = 0;
          let failed = 0;
          let remaining = 0;
          const today = new Date();
          const progress = challengeDoc.data().progress || [];

          taskList.forEach(task => {
            const taskDate = new Date(challengeDoc.data().start_date.seconds * 1000);
            taskDate.setDate(taskDate.getDate() + task.day_number - 1);

            // Verificar si el reto está marcado como completado en el array de progreso usando el taskId
            const taskProgress = progress.find(p => p.taskId === task.id);

            if (taskProgress && taskProgress.completed) {
              completed++;
            } else if (today >= taskDate && (!taskProgress || !taskProgress.completed)) {
              failed++;
            } else {
              remaining++;
            }
          });

          setCompletedCount(completed);
          setFailedCount(failed);
          setRemainingCount(remaining);
          setProgressPercentage(((completed / taskList.length) * 100).toFixed(2));
        } else {
          console.error("No se encontró el desafío para este usuario.");
        }
      } catch (error) {
        console.error("Error fetching challenge progress:", error);
      }
    };

    fetchChallengeProgress();
  }, [challengeId, userId]);

  const getStatusIcon = (task) => {
    const today = new Date();
    const startDate = new Date(challenge.start_date.seconds * 1000);
    const taskDate = new Date(startDate);
    taskDate.setDate(taskDate.getDate() + task.day_number - 1);

    const progress = challenge.progress || [];
    const taskProgress = progress.find(p => p.taskId === task.id);

    if (taskProgress && taskProgress.completed) {
      return <span className="status-icon completed">✔</span>;
    } else if (today >= taskDate && (!taskProgress || !taskProgress.completed)) {
      return <span className="status-icon failed">❌</span>;
    } else {
      return <span className="status-icon remaining">⁉</span>;
    }
  };

  if (!challenge) {
    return <div>Cargando datos del desafío...</div>;
  }

  return (
    <div className="challenge-progress-container">
      <h2 className="challenge-title">
        <span className="progress-title">Progreso del Desafío:</span><br />
        {challenge.challenge_name}
      </h2>

      <div className="statistics-container">
        <div className="task-grid stats">
          <div className="stat-box">
            Retos completados: <span className="completed">{completedCount}</span>
          </div>
          <div className="stat-box">
            Retos fallados: <span className="failed">{failedCount}</span>
          </div>
          <div className="stat-box">
            Retos que faltan: <span className="remaining">{remainingCount}</span>
          </div>
        </div>
        <div className="progress-total">
          Progreso Total: <span className="progress-percentage">{progressPercentage}%</span>
        </div>
      </div>

      <button className="return-button" onClick={() => navigate("/user-challenges")}>Volver a la pantalla inicial</button>

      <div className="tasks-spacing"></div>

      <div className="task-grid">
        {tasks.map(task => (
          <div key={task.id} className="task-item">
            <div className="task-info">
              <div className="task-day">Día {task.day_number}</div>
              <div className="task-title">{task.task_name}</div>
            </div>
            <div className="task-status">{getStatusIcon(task)}</div>
          </div>
        ))}
      </div>

      <button className="return-button bottom" onClick={() => navigate("/user-challenges")}>Volver a la pantalla inicial</button>
    </div>
  );
};

export default ChallengeProgress;
