import React, { useEffect, useState } from "react";
import { collection, query, getDocs } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import { db } from "./firebase";
import './UserChallenges.css'; // Importa los estilos actualizados

const UserChallenges = ({ userId }) => {
  const [activeChallenges, setActiveChallenges] = useState([]);
  const [aphorism, setAphorism] = useState(null);
  const [completedStatus, setCompletedStatus] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChallenges = async () => {
      const activeChallengesQuery = query(collection(db, "users", userId, "user_challenges"));
      const activeChallengesSnapshot = await getDocs(activeChallengesQuery);
      const userActiveChallenges = activeChallengesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setActiveChallenges(userActiveChallenges);

      const aphorismsSnapshot = await getDocs(collection(db, "aphorisms"));
      const aphorismDocs = aphorismsSnapshot.docs;
      if (aphorismDocs.length > 0) {
        const randomIndex = Math.floor(Math.random() * aphorismDocs.length);
        setAphorism(aphorismDocs[randomIndex].data());
      }

      // Verificar si cada desafío está completado
      const status = {};
      for (const challenge of userActiveChallenges) {
        const currentDayNumber = getCurrentDayNumber(challenge.start_date);
        status[challenge.id] = await isChallengeCompletedForToday(challenge, currentDayNumber);
      }
      setCompletedStatus(status);
    };

    fetchChallenges();
  }, [userId]);

  const formatDate = (timestamp) => {
    const date = new Date(timestamp.seconds * 1000);
    return date.toLocaleDateString("es-ES", { day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  const getCurrentDayNumber = (startDate) => {
    const now = new Date();
    const start = new Date(startDate.seconds * 1000);
  
    const nowDateOnly = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const startDateOnly = new Date(start.getFullYear(), start.getMonth(), start.getDate());
  
    const differenceInTime = nowDateOnly.getTime() - startDateOnly.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays + 1;
  };

  const calculateEndDate = (startDate, days) => {
    const start = new Date(startDate.seconds * 1000);
    start.setDate(start.getDate() + days);
    return start.toLocaleDateString("es-ES", { day: '2-digit', month: '2-digit', year: 'numeric' });
  };

  const isChallengeCompletedForToday = async (challenge, currentDayNumber) => {
    const tasksSnapshot = await getDocs(collection(db, "challenges", challenge.challenge_id, "tasks"));
    const taskList = tasksSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));
    const todayTask = taskList.find(task => task.day_number === currentDayNumber);

    if (!todayTask) return false;

    return challenge.progress.some(progress => progress.taskId === todayTask.id && progress.completed);
  };

  return (
    <div className="user-challenges-container">
      <ul className="challenge-list">
        {activeChallenges.map(challenge => {
          const currentDayNumber = getCurrentDayNumber(challenge.start_date);
          const totalDays = 31; // Asumimos que todos los retos tienen 31 días, ajústalo si es necesario
          const endDate = calculateEndDate(challenge.start_date, totalDays);
          const isCompleted = completedStatus[challenge.id]; // Estado de completitud del reto

          return (
            <li key={challenge.id} className="challenge-item">
              <div className="challenge-header">
                <div className="challenge-title">{challenge.challenge_name}</div>
                {!isCompleted ? (
                  <button className="daily-button" onClick={() => navigate(`/daily-challenge/${challenge.id}/${currentDayNumber}`)}>Reto de Hoy</button>
                ) : (
                  <div className="challenge-completed">
                    Ya has completado el reto de hoy.<br />
                    <span>¡Recarga las pilas para mañana!</span>
                  </div>
                )}
              </div>
              <div className="challenge-details">
                <div className="challenge-dates">
                  <div className="label">Inicio: <span className="date">{formatDate(challenge.start_date)}</span></div>
                  <div className="label">Fin: <span className="date">{endDate}</span></div>
                </div>
                <div className="challenge-day-container">
                  <div className="challenge-day">
                    Estás en el día <span>{currentDayNumber}</span> de {totalDays}
                  </div>
                  <button className="progress-button" onClick={() => navigate(`/challenge-progress/${challenge.id}`)}>Ver Progreso</button>
                </div>
              </div>
              <div className="feedback-section">
                <span className="feedback-text">¿Te has cansado de participar o has terminado el desafío?</span>
                <button
                  className="finish-button"
                  onClick={() => navigate(`/feedback/${challenge.id}`)}
                >
                  Finalizar Desafío
                </button>
              </div>
            </li>
          );
        })}
      </ul>

      {aphorism && (
        <div className="quote">
          <div className="quote-text">"{aphorism.phrase}"</div>
          <div className="quote-author">— {aphorism.author}</div>
        </div>
      )}
    </div>
  );
};

export default UserChallenges;
