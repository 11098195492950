import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db } from "./firebase";
import { collection, addDoc, doc, getDoc } from "firebase/firestore";
import './Feedback.css'; // Asegúrate de tener este archivo actualizado con los estilos proporcionados

const Feedback = ({ userId }) => {
  const { challengeId } = useParams();
  const [satisfaction, setSatisfaction] = useState(5);
  const [easyToUse, setEasyToUse] = useState("Sí");
  const [challengeSatisfaction, setChallengeSatisfaction] = useState(5);
  const [wouldUseAgain, setWouldUseAgain] = useState("Sí");
  const [perceivedValue, setPerceivedValue] = useState(0);
  const [commitmentDuration, setCommitmentDuration] = useState([]);
  const [technicalIssues, setTechnicalIssues] = useState("No");
  const [currentDayNumber, setCurrentDayNumber] = useState(null);
  const [additionalFeedback, setAdditionalFeedback] = useState(""); // Nuevo estado para el textarea
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchChallengeData = async () => {
      const challengeDoc = await getDoc(
        doc(db, "users", userId, "user_challenges", challengeId)
      );
      if (challengeDoc.exists()) {
        const startDate = challengeDoc.data().start_date;
        const dayNumber = calculateCurrentDayNumber(startDate);
        setCurrentDayNumber(dayNumber);
      }
    };

    fetchChallengeData();
  }, [challengeId, userId]);

  const calculateCurrentDayNumber = (startDate) => {
    const now = new Date();
    const start = new Date(startDate.seconds * 1000);

    const nowDateOnly = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    const startDateOnly = new Date(
      start.getFullYear(),
      start.getMonth(),
      start.getDate()
    );

    const differenceInTime = nowDateOnly.getTime() - startDateOnly.getTime();
    const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
    return differenceInDays + 1;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await addDoc(collection(db, "feedback"), {
      userId,
      challengeId,
      currentDayNumber,
      satisfaction,
      easyToUse,
      challengeSatisfaction,
      wouldUseAgain,
      perceivedValue,
      commitmentDuration,
      technicalIssues,
      additionalFeedback, // Guardar el comentario adicional
      submittedAt: new Date(),
    });

    setShowPopup(true);
  };

  return (
    <div className="feedback-container">
      <div className="feedback-intro">
        <h2>¡Gracias por tu participación!</h2>
        <p>
          Si has llegado al final o te has cansado y quieres abandonar el
          desafío en algún punto, tan sólo te voy a pedir 1 minuto de tu tiempo.
          🙏
        </p>
        <p>
          <span>Tu feedback es muy extremadamente importante</span> para mí en
          esta fase BETA del proyecto.
        </p>
      </div>

      <form onSubmit={handleSubmit} className="feedback-form">
        <div className="question">
          <label>
            ¿Cuál es tu satisfacción en cuanto a facilidad de uso de la
            aplicación?
          </label>
          <select
            value={satisfaction}
            onChange={(e) => setSatisfaction(e.target.value)}
          >
            {[...Array(10)].map((_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>

        <div className="question">
          <label>
            ¿Te ha resultado fácil aprender a usarla intuitivamente?
          </label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="easyToUse"
                value="Sí"
                checked={easyToUse === "Sí"}
                onChange={(e) => setEasyToUse(e.target.value)}
              />
              Sí
            </label>
            <label>
              <input
                type="radio"
                name="easyToUse"
                value="No"
                checked={easyToUse === "No"}
                onChange={(e) => setEasyToUse(e.target.value)}
              />
              No
            </label>
          </div>
        </div>

        <div className="question">
          <label>
            ¿Cuál es tu satisfacción en relación al desafío y los retos?
          </label>
          <select
            value={challengeSatisfaction}
            onChange={(e) => setChallengeSatisfaction(e.target.value)}
          >
            {[...Array(10)].map((_, i) => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>

        <div className="question">
          <label>
            ¿Usarías esta aplicación, con diferentes desafíos para trabajar tu
            desarrollo personal en diferentes áreas de tu vida: salud,
            intelecto, emociones, carácter, espiritualidad, amor, familia, vida
            social, finanzas, trabajo o estilo de vida, y por supuesto construir
            un estilo de vida de Alto Rendimiento?
          </label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="wouldUseAgain"
                value="Sí"
                checked={wouldUseAgain === "Sí"}
                onChange={(e) => setWouldUseAgain(e.target.value)}
              />
              Sí
            </label>
            <label>
              <input
                type="radio"
                name="wouldUseAgain"
                value="No"
                checked={wouldUseAgain === "No"}
                onChange={(e) => setWouldUseAgain(e.target.value)}
              />
              No
            </label>
          </div>
        </div>

        <div className="question">
          <label>
            ¿En tu opinión, cuál es el valor que estimas que tiene participar en
            un desafío así?
          </label>
          <div className="input-with-symbol">
            <span>$</span>
            <input
              type="number"
              value={perceivedValue}
              onChange={(e) => setPerceivedValue(e.target.value)}
              min="0"
            />
          </div>
          <p className="value-note">
            *Te pido la mayor sinceridad por favor, si no percibes ningún valor
            déjalo en 0.
          </p>
        </div>

        <div className="question">
          <label>
            ¿Cuál es la duración de los desafíos con la que crees que
            conseguirías comprometerte?
          </label>
          <div className="checkbox-group">
            <label>
              <input
                type="checkbox"
                value="Menos de 31 días"
                checked={commitmentDuration.includes("Menos de 31 días")}
                onChange={(e) => {
                  const { checked, value } = e.target;
                  setCommitmentDuration((prev) =>
                    checked
                      ? [...prev, value]
                      : prev.filter((v) => v !== value)
                  );
                }}
              />
              Menos de 31 días
            </label>
            <label>
              <input
                type="checkbox"
                value="31 días"
                checked={commitmentDuration.includes("31 días")}
                onChange={(e) => {
                  const { checked, value } = e.target;
                  setCommitmentDuration((prev) =>
                    checked
                      ? [...prev, value]
                      : prev.filter((v) => v !== value)
                  );
                }}
              />
              31 días
            </label>
            <label>
              <input
                type="checkbox"
                value="62 días"
                checked={commitmentDuration.includes("62 días")}
                onChange={(e) => {
                  const { checked, value } = e.target;
                  setCommitmentDuration((prev) =>
                    checked
                      ? [...prev, value]
                      : prev.filter((v) => v !== value)
                  );
                }}
              />
              62 días
            </label>
            <label>
              <input
                type="checkbox"
                value="100 días"
                checked={commitmentDuration.includes("100 días")}
                onChange={(e) => {
                  const { checked, value } = e.target;
                  setCommitmentDuration((prev) =>
                    checked
                      ? [...prev, value]
                      : prev.filter((v) => v !== value)
                  );
                }}
              />
              100 días
            </label>
          </div>
        </div>

        <div className="question">
          <label>¿Has experimentado problemas técnicos?</label>
          <div className="radio-group">
            <label>
              <input
                type="radio"
                name="technicalIssues"
                value="Sí"
                checked={technicalIssues === "Sí"}
                onChange={(e) => setTechnicalIssues(e.target.value)}
              />
              Sí
            </label>
            <label>
              <input
                type="radio"
                name="technicalIssues"
                value="No"
                checked={technicalIssues === "No"}
                onChange={(e) => setTechnicalIssues(e.target.value)}
              />
              No
            </label>
          </div>
        </div>

        {/* Nueva pregunta con textarea */}
        <div className="question">
          <label>
            ¿Quieres dejarme algún comentario o feedback más extenso? Aquí tienes el espacio para hacerlo:
          </label>
          <textarea
            value={additionalFeedback}
            onChange={(e) => setAdditionalFeedback(e.target.value)}
            rows="5"
            placeholder="Escribe aquí tus comentarios..."
          />
        </div>

        <button type="submit" className="submit-button">
          Enviar
        </button>
        <button
          type="button"
          className="return-button"
          onClick={() => navigate("/user-challenges")}
        >
          Volver a la pantalla inicial
        </button>
      </form>

      {showPopup && (
        <>
          <div className="popup-overlay"></div>
          <div className="popup">
            <p>
              De corazón, muchísimas gracias por participar en este proyecto,
              por tu tiempo, tu energía y tu opinión. 💛
            </p>
            <button onClick={() => navigate("/user-challenges")}>
              Volver a la pantalla inicial
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Feedback;
