import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC7VccF0dUhOLCe6lDrxYeL2BziAkfwQMM",
  authDomain: "blows-401bc.firebaseapp.com",
  projectId: "blows-401bc",
  storageBucket: "blows-401bc.appspot.com",
  messagingSenderId: "98106763607",
  appId: "1:98106763607:web:b3bed1d8758d6f38d43612",
  measurementId: "G-NXGSEZG7LH"
};

// Inicializar Firebase
const app = initializeApp(firebaseConfig);

// Inicializar Firebase Authentication y Firestore
const auth = getAuth(app);
const db = getFirestore(app);

// Exportar auth y db para usarlos en otras partes de la app
export { auth, db };