import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { doc, getDoc, updateDoc, collection, getDocs } from "firebase/firestore";
import { db } from "./firebase";
import './DailyChallengeDetails.css';

const DailyChallengeDetails = ({ userId }) => {
  const { challengeId, dayNumber } = useParams();
  const [challenge, setChallenge] = useState(null);
  const [task, setTask] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const navigate = useNavigate(); // Hook para navegar entre rutas

  useEffect(() => {
    const fetchChallengeDetails = async () => {
      const challengeDoc = await getDoc(doc(db, "users", userId, "user_challenges", challengeId));
      if (challengeDoc.exists()) {
        setChallenge(challengeDoc.data());

        // Obtener los detalles del reto específico (task)
        const tasksSnapshot = await getDocs(collection(db, "challenges", challengeDoc.data().challenge_id, "tasks"));
        const taskList = tasksSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        const todayTask = taskList.find(t => t.day_number === parseInt(dayNumber));
        setTask(todayTask);
      }
    };

    fetchChallengeDetails();
  }, [challengeId, dayNumber, userId]);

  const markChallengeAsCompleted = async () => {
    const challengeRef = doc(db, "users", userId, "user_challenges", challengeId);

    // Añadir el progreso usando el taskId en lugar de day_number
    await updateDoc(challengeRef, {
      progress: [
        ...challenge.progress,
        { taskId: task.id, completed: true } // Guardar el taskId y el estado completado
      ]
    });
    navigate("/user-challenges");
  };

  return (
    <div className={`daily-challenge-container ${showPopup ? 'blur-background' : ''}`}>
      {showPopup && <div className="overlay"></div>} {/* Overlay para oscurecer el fondo */}
      {challenge && task ? (
        <div>
          <h2 className="challenge-title">Día {dayNumber} | {task.task_name}</h2>
          <p className="challenge-description">{task.task_description}</p>
          <div className="button-container">
            <button className="complete-button" onClick={() => setShowPopup(true)}>Marcar como Completado</button>
            <button className="back-button" onClick={() => navigate("/user-challenges")}>Volver a la pantalla inicial</button>
          </div>
        </div>
      ) : (
        <p>Cargando detalles del reto...</p>
      )}
  
      {showPopup && (
        <div className="popup">
          <p>¿De verdad has cumplido el reto? Si lo marcas como cumplido no hay vuelta atrás.</p>
          <button className="complete-popup-button" onClick={markChallengeAsCompleted}>¡Cumplido!</button>
          <button className="cancel-popup-button" onClick={() => setShowPopup(false)}>Todavía no</button>
        </div>
      )}
    </div>
  );
};

export default DailyChallengeDetails;
