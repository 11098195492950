import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { auth, db } from "./firebase"; // Asegúrate de importar 'db' para usar Firestore
import { signInWithEmailAndPassword, createUserWithEmailAndPassword, setPersistence, browserLocalPersistence, browserSessionPersistence } from "firebase/auth";
import { doc, setDoc } from "firebase/firestore"; // Importa setDoc para guardar en Firestore
import './Auth.css'; // Importar el archivo CSS

const Auth = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isRegistering, setIsRegistering] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // Redirigir al usuario a /user-challenges si ya está autenticado
  useEffect(() => {
    if (auth.currentUser) {
      navigate("/user-challenges");
    }
  }, [navigate]);

  const handleAuth = () => {
    setError(""); // Limpiar el mensaje de error antes de intentar autenticarse

    const persistence = rememberMe ? browserLocalPersistence : browserSessionPersistence;

    setPersistence(auth, persistence)
      .then(() => {
        if (isRegistering) {
          if (password !== confirmPassword) {
            setError("Las contraseñas no coinciden.");
            return;
          }

          // Registro del usuario
          return createUserWithEmailAndPassword(auth, email, password)
            .then(async (userCredential) => {
              const userId = userCredential.user.uid;
              // Crear el documento en 'users' con el campo email
              await createUserDocument(userId, email);
              // Asignar el desafío al usuario al registrarse
              await assignChallengeToUser(userId);
              navigate("/user-challenges"); // Redirigir al componente UserChallenges después del registro
            });
        } else {
          // Login del usuario
          return signInWithEmailAndPassword(auth, email, password)
            .then(() => {
              navigate("/user-challenges"); // Redirigir al componente UserChallenges después del login
            });
        }
      })
      .catch((error) => {
        console.log("Error Code:", error.code); // Depuración: Ver el código de error
        handleError(error.code);
      });
  };

  // Función para crear el documento del usuario en la colección 'users'
  const createUserDocument = async (userId, email) => {
    await setDoc(doc(db, 'users', userId), {
      email: email, // Guardamos el campo email
    });
  };

  // Función para asignar un desafío al usuario en Firestore
  const assignChallengeToUser = async (userId) => {
    const challengeId = 'Qz2JXAlUfaMoKsLMq6O6'; // ID del desafío ya existente en Firestore
    const challengeName = '31 días de Alto Rendimiento';
    const startDate = new Date();

    // Crear documento en 'user_challenges' en Firestore para el usuario
    await setDoc(doc(db, 'users', userId, 'user_challenges', challengeId), {
      challenge_id: challengeId,
      challenge_name: challengeName,
      start_date: startDate,
      progress: [] // Progreso vacío al principio
    });
  };

  const handleError = (errorCode) => {
    switch (errorCode) {
      case "auth/invalid-email":
        setError("El formato del email es inválido. Por favor, verifica.");
        break;
      case "auth/user-disabled":
        setError("Esta cuenta ha sido deshabilitada. Contacta con soporte.");
        break;
      case "auth/user-not-found":
      case "auth/wrong-password":
        setError("El email o la contraseña son incorrectos.");
        break;
      case "auth/email-already-in-use":
        setError("Este email ya está registrado. Inicia sesión o usa otro email.");
        break;
      case "auth/weak-password":
        setError("La contraseña es demasiado débil. Usa al menos 6 caracteres.");
        break;
      case "auth/invalid-credential":
        setError("El email o la contraseña son incorrectos.");
        break;
      default:
        setError("Ocurrió un error. Inténtalo de nuevo más tarde.");
        break;
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleAuth();  // Ejecuta la acción de login o registro
    }
  };

  return (
    <div className="auth-container" onKeyDown={handleKeyPress}>
      <h2>{isRegistering ? "Registrarse" : "Iniciar Sesión"}</h2>
      <input
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <input
        type="password"
        placeholder="Contraseña"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
      />
      {isRegistering && (
        <input
          type="password"
          placeholder="Confirmar Contraseña"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
      )}
      <label>
        <input
          type="checkbox"
          checked={rememberMe}
          onChange={(e) => setRememberMe(e.target.checked)}
        />
        Mantener sesión iniciada
      </label>
      {error && <p className="error-message">{error}</p>}
      <button className="login-button" onClick={handleAuth}>
        {isRegistering ? "Registrarse" : "Iniciar Sesión"}
      </button>
      <button
        className="register-button"
        onClick={() => {
          setIsRegistering(!isRegistering);
          setError("");
        }}
      >
        {isRegistering ? "¿Ya tienes cuenta? Inicia sesión" : "¿No tienes cuenta? Regístrate"}
      </button>

      {/* Aquí se añade el texto informativo al final */}
      <p className="beta-notice">
        *Esta aplicación está en fase Beta. El objetivo de esta fase es probar tanto el aspecto técnico como la efectividad e idea del proyecto.
      </p>
      <p className="beta-notice">
        Soy consciente de que tiene muchas cosas por mejorar y por supuesto cualquier feedback es bienvenido.
      </p>
      <p className="beta-notice">
        Si experimentas algún problema o quieres hablar conmigo, por favor, no dudes en escribirme a <a href="mailto:israel@the-dreammaker.com">israel@the-dreammaker.com</a>.
      </p>
      <p className="beta-notice">
        El único dato que recojo de información personal es tu email, el será privado y no será usado para absolutamente nada más, ni publicidad, ni marketing, ni ningún otro uso.
      </p>
    </div>
  );
};

export default Auth;
